
<template>
  <component :is="comp" :inNote="note" @noteViewChange="noteViewChange" @refreshNote="getNote" />
</template>
<script>

import TextNote from "./Text/NoteView.vue";
import TemplateNote from "./Templated/TemplatedNoteView.vue";
import BladedNote from "./Bladed/BladedNoteView.vue";
const NotesService = require("Services/NotesService");
import MixpanelService from "Services/MixpanelService";
import { shallowRef } from "vue";

export default {
  name: "NoteEntryPoint",
  components: { TextNote, TemplateNote },
  data () {
    return {
      comp: undefined,
      currComp: '',
      noteId: this.noteId,
      note: undefined,
      views: ['no_template', 'soap']
    };
  },
  async mounted () {
    let useURLView = !!this.$route.query.view && this.views.includes(this.$route.query.view);
    // for scribe users we route differently for now
    if ((this.isUserScribe || this.isUserVerified) && useURLView) {
      switch (this.$route.query.view) {
        case ("no_template"):
          this.comp = shallowRef(TextNote);
          this.currComp = "no_template"; break;
        case ("soap"):
          this.comp = shallowRef(TemplateNote);
          this.currComp = "soap"; break;
      }
    } else if (useURLView) {
      switch (this.$route.query.view) {
        case ("no_template"): // this should be considered a fallback, most users will be sent to the bladed note
          this.comp = shallowRef(BladedNote);
          this.currComp = "no_template"; break;
        case ("soap"):
          this.comp = shallowRef(BladedNote);
          this.currComp = "soap"; break;
      }
    }
    await this.getNote(useURLView);
  },
  watch: {
    $route: {
      async handler (newVal, oldVal) {
        let useURLView = !!newVal.query.view && this.views.includes(newVal.query.view);
        if (newVal.params.noteId !== oldVal.params.noteId) {
          await this.getNote(useURLView);
        }
      },
      deep: true,
    }
  },
  computed: {
    isUserScribe () {
      if (!this.$store.getters.getUserGroups) return false;
      return this.$store.getters.getUserGroups.findIndex(x => x === "Scribe") !== -1;
    },
    isUserVerified () {
      return this.$store.getters.getUserPerms.verified;
    }
  },
  methods: {
    async getNote (useURLView) {
      if (!this.$route.params.noteId) return;
      await NotesService.GetNote(this.$route.params.noteId)
        .then((resp) => {
          this.note = resp.data;
          if(useURLView) {
            // validate url view
            if (!this.note.note_templates.map(x => x.type).some(x=>x==this.$route.query.view)) {
              this.currComp = this.note.metadata.user_preferred_view ? this.note.metadata.user_preferred_view :
              this.note.note_templates.find(x => x.type == 'soap') ? 'soap' : 'no_template';
            }

          }else  {
            // again route differently for scribes
            if ((this.isUserScribe || this.isUserVerified)) {
              if (!this.note || this.note.note_templates.length === 0) {
                this.comp = shallowRef(TextNote);
                this.currComp = "no_template";
              }
              else {
                this.comp = shallowRef(TemplateNote);
                this.currComp = "soap";
              }
            } else {
              if (!this.note || this.note.note_templates.length === 0) {
                this.comp = shallowRef(BladedNote);
                this.currComp = "no_template";
              }
              else {
                this.comp = shallowRef(BladedNote);
                this.currComp = this.note.metadata.user_preferred_view ? this.note.metadata.user_preferred_view : "soap";
                // this.currComp = "soap";
              }
            }
          }
        }).catch((err) => {
          console.log(err);
          this.$toast.error({ message: "There was an error with retrieving this note!" });
          this.setTimeout(() => {
            this.$router.push('/notes');
          }, 0);
        });

      this.$router.replace({ query: { view: this.currComp } });
      this.updateLatestPreferredView(this.currComp);
    },
    noteViewChange (view) {
      if (view) {
        switch (view) {
          case ("soap"):
            if (this.isUserScribe) {
              this.comp = shallowRef(TemplateNote);
            } else {
              this.comp = shallowRef(BladedNote);
            }
            break;
          case ("no_template"):
            if (this.isUserScribe) {
              this.comp = shallowRef(TextNote);
            } else {
              this.comp = shallowRef(BladedNote);
            }
            break;
        }
        this.currComp = view;
      } else {
        switch (this.currComp) {
          case ("no_template"):
            if (this.isUserScribe) {
              this.comp = shallowRef(TemplateNote);
            } else {
              this.comp = shallowRef(BladedNote);
            }
            this.currComp = "soap";

            break;
          case ("soap"):
            this.comp = shallowRef(TextNote);
            this.currComp = "no_template";
            break;
        }
      }
      this.$router.replace({ query: { view: this.currComp } });
      this.updateLatestPreferredView(this.currComp);
      this.logViewChange(this.currComp);
    },
    updateLatestPreferredView (view) {
      if (this.views.includes(view)) {
        // don't update when it doesn't change
        if (!this.note) return;
        if (this.note.metadata.user_preferred_view === view) return;
        this.note.metadata.user_preferred_view = view;
        if (this.note.state == 0 || this.note.state == 5) return; // guard writing when note is not processed
        NotesService.UpdateNote({ note_id: this.note.id, title: this.note.title, metadata: this.note.metadata })
          .catch(e => console.warn(e));
      } else {
        console.warn("unexpected note view selection: ", view);
      }
    },
    logViewChange (view) {
      let subId = this.$store.getters.getSubscriptionId;
      MixpanelService.Track("AdminPortal:NotesViewSelection", {
        subscription_id: subId,
        note_id: this.noteId,
        view: view,
      });
    }
  },
};
</script>