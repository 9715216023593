<template>
  <BladeHeader>
    <div class="m-auto w-[278px] text-neutral-alt text-base font-extrabold">
      User Settings
    </div>
  </BladeHeader>
  <FadeInWrapper>

    <div v-if="userProfile" class="flex flex-col w-[278px] mx-auto mt-8 border  border-main-alt rounded-md bg-white">
      <!-- background for image -->
      <div class="relative h-28 mb-24 w-full rounded-t-md"
           :style="`background-color:${imageBorder};`">
        <div class="absolute flex transform translate-y-1/2 -translate-x-1/2 bottom-0 left-1/2">
          <baseIcon v-if="!userProfile.profilePhoto" :text="initials" themeName="main" diameter="8" class="bg-white">
          </baseIcon>
          <img v-else :src="`https://storage.googleapis.com/assets.talkatoo.ai/profile-photos/${userProfile.profilePhoto}`"
               class="min-h-32 min-w-32 h-32 w-32 rounded-full"
               :style="imageBorder ? `border: ${imageBorder} 2px solid;` : ''" />
        </div>
      </div>
      <div class="flex flex-col justify-center text-center mb-12">
        <span id="fullName" class="clamp-line-count break-all mb-3 mx-2 text-2xl font-bold font-montserrat text-neutral-darker">
          {{ fullName }}
        </span>
        <span class="clamp-line-count text-neutral font-roboto text-sm break-words" style>
          {{ userProfile.email }}
        </span>
        <span class="clamp-line-count text-neutral font-roboto text-sm">
          {{ userProfile.jobTitle }}
        </span>
      </div>
    </div>
    <div class="flex w-[278px] mx-auto mt-5">
      <template v-if="userId == currentUser">
        <div class="link-subgroup transition-colors">
          <RouterLink class="link-button" to="/profile">
            My Profile
            <span class="material-icons text-main-alt absolute right-4"> keyboard_arrow_right</span>
          </RouterLink>
          <!-- <RouterLink class="link-button" to="/dahecky">
            My Words
            <span class="material-icons text-main-alt absolute right-4"> keyboard_arrow_right</span>
          </RouterLink> -->
          <RouterLink class="link-button" to="/settings/templates">
            My Templates
            <span class="material-icons text-main-alt absolute right-4"> keyboard_arrow_right</span>
          </RouterLink>
          <RouterLink class="link-button" to="/settings/talkatexts">
            My Talkatexts
            <span class="material-icons text-main-alt absolute right-4"> keyboard_arrow_right</span>
          </RouterLink>
        </div>
      </template>

    </div>
    <div class="flex justify-center mt-auto mb-20">
      <button v-if="userId != currentUser && $store.getters.getUserPerms.user_management"
              class="btn-secondary-delete py-2 px-4 "
              @click="toggleRemoveModal">

        Remove This User
      </button>
    </div>
  </FadeInWrapper>

  <teleport to="body">
    <BaseModal title="Remove User" :toDisplay="showRemoveModal" @toggleEvent="toggleRemoveModal">
      <div class="p-4">
        <div class="flex flex-col justify-center py-6">
          <baseIcon v-if="userProfile && !userProfile?.profilePhoto" :text="initials" themeName="main" diameter="8">
          </baseIcon>
          <img v-else :src="`https://storage.googleapis.com/assets.talkatoo.ai/profile-photos/${userProfile?.profilePhoto}`"
               class="h-28 w-28 rounded-full mx-auto"
               :style="imageBorder ? `border: ${imageBorder} 2px solid;` : ''"
          />
          <span class="pt-6 pb-4 text-2xl font-bold text-center"> Are you sure you want <br /> to remove this user?</span>
          <span class="text-center text-base text-main-alt font-bold font-roboto">{{ userProfile.email }}</span>
        </div>
        <div class="flex flex-col mx-2 sm:mx-12 py-5 bg-error bg-opacity-20 border-l-2 border-error">
          <span class="material-icons text-error text-4xl text-center">warning</span>
          <span class="ml-12 pt-4 pr-8 sm:pr-20 text-neutral-darker">
            This cannot be undone, and the user will lose the ability <br class="hidden md:block" />
            to dictate and record notes.
          </span>
        </div>
      </div>

      <div class="flex w-full justify-center lg:justify-end  border-t border-neutral-light py-6 lg:px-11">
        <button class="mr-3 py-2 px-4 text-xs text-neutral font-bold rounded-lg"
                @click="toggleRemoveModal">
          CANCEL
        </button>
        <button class="btn-primary-delete px-4 py-2 text-xs font-bold bg-opacity-0"
                @click="removeUser">
          REMOVE USER
        </button>
      </div>
    </BaseModal>
  </teleport>

</template>
<script>
const UserService = require('Services/UserService');
import SubscriptionService from "Services/SubscriptionService";
import BaseIcon from "Components/ui/BaseIcon";
import BaseModal from "Components/ui/BaseModal";
import BladeHeader from "./BladeHeader.vue";
import FadeInWrapper from "../FadeInWrapper.vue";

export default {
  name: "ProfileBlade",
  components: {
    BaseIcon, BaseModal, BladeHeader,
    FadeInWrapper
},
  props: {},
  data () {

    const queryParams = this.$router.currentRoute.value.query;
    const pathParams = this.$route.params;
    let userId = 0;
    if (queryParams.id) {
      userId = queryParams.id;
    } else if (pathParams.userId) {
      userId = pathParams.userId;
    } else {
      userId = this.$store.getters.getUserId;
    }
    return {
      currentUser: this.$store.getters.getUserId,
      userId,

      fullName: '',
      email: '',
      firstName: '',
      lastName: '',
      loading: true,
      phone: '',
      userProfile: undefined,

      showRemoveModal: false,

      wsIds: [],
    };
  },
  async created () {
    this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onInternalEvent(this.internalEventHandler));

    if (this.currentUser == this.userId) {
      let userProfile = this.$store.getters.getUserProfile;
      this.handleProfile(userProfile);
      this.loading = false;
    }
    else {

      Promise.all([UserService.GetUser(this.userId), UserService.GetUserProfile(this.userId), /* permsPromise */])
        .then((resp) => {
          if (resp[1].data.profile_photo == '') {
            let defaultPhoto = `profile-${parseInt(this.userId / 2) % 20 + 1}.png`;
            resp[1].data.profile_photo = defaultPhoto;
          }
          resp[1].data.firstName = resp[1].data.first_name,
            resp[1].data.lastName = resp[1].data.last_name,
            resp[1].data.jobTitle = resp[1].data.job_title,
            resp[1].data.phoneNumber = resp[1].data.phone_number,
            resp[1].data.profilePhoto = resp[1].data.profile_photo,
            resp[1].data.email = resp[0].data.email,
            this.handleProfile(resp[1].data);
        })
        .catch((err) => {
          console.log(err);
        }).finally(() => {
          this.loading = false;
        });
    }
  },
  unmounted () {
    this.$store.getters.getWebsocketEventHandler.removeEvents(this.wsIds);
  },
  watch: {
    currentUserProfile (newVal, oldVal) {
      if (this.currentUser == this.userId) {
        let userProfile = this.$store.getters.getUserProfile;
        this.handleProfile(userProfile);
        this.loading = false;
      }
    }
  },
  computed: {
    currentUserProfile () {
      return this.$store.getters.getUserProfile;
    },
    imageBorder () {
      if (!this.userProfile?.profilePhoto) return '';
      if (this.userProfile.profilePhoto == '') return '';
      let img = this.userProfile.profilePhoto.split('.')[0];
      let index = img.split('-')[1] - 1;
      let colorKey = parseInt(index / 5);
      return ["#52BAE2", "#6BEDD3", "#97ABED", "#FFD37A"][colorKey];
    },

    // fallback if user profile has no image
    initials () {
      //testing first and lastName for undefined
      let firstName = "";
      let initials = "";
      if (this.firstName) {
        firstName = this.firstName.trim();
        //setting initials with name sections
        if (firstName.length > 0) {
          initials += firstName[0].toUpperCase();
        }
      }

      let lastName = "";
      if (this.lastName) {
        lastName = this.lastName.trim();
        //setting initials with name sections
        if (lastName.length > 0) {
          initials += lastName[0].toUpperCase();
        }
      }

      if (initials === "") {
        let email = "";
        if (this.email) {
          email = this.email.trim();
          if (email.length > 0) {
            initials += email[0].toUpperCase();
          }
        }
      }
      return initials;
    },
  },
  methods: {
    toggleRemoveModal () {
      this.showRemoveModal = !this.showRemoveModal;
    },
    handleProfile (userProfile) {
      // this.tmpProfile = structuredClone(userProfile);
      this.userProfile = userProfile;
      if (userProfile.firstName || userProfile.lastName) {
        this.firstName = userProfile.firstName;
        this.lastName = userProfile.lastName;
        this.fullName = userProfile.firstName + ' ' + userProfile.lastName;
      }
      this.email = userProfile.email;
      this.loading = false;
    },
    internalEventHandler (e) {
      if (e.type === "UserManagement:UpdateProfile") {
        this.userProfile = e.profile;
      }
    },
    removeUser () {
      this.showRemoveModal = false;
      this.pendingChange = true;
      SubscriptionService.DeleteSubscriptionUser(this.userId)
        .then((resp) => {
          if (this.userId != this.currentUser) {
            this.$router.push('/users');
            this.$toast.success({ message: `You successfully removed ${this.firstName} from the subscription!` });
          } else {
            this.$store
              .dispatch("logout");
            this.$toast.success({ message: "You successfully removed yourself from the subscription!" });
          }
        }).catch((err) => {
          this.currPassError = "Password was incorrect";
        }).finally(() => {
          this.pendingChange = false;
        });
    }
  }
};
</script>
<style>
.link-subgroup>.router-link-active {
  @apply bg-pastel-blue-light;
  @apply bg-opacity-50;
}
</style>