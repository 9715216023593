<template>
  <div class="relative">
    <!-- :class="['blade sticky top-0 h-[100vh] bg-[#E7EBF2]', isDragging ? 'resizing' : '']" -->
    <div
      :class="['blade sticky top-0 h-[100vh] bg-[#E7EBF2]', !isDragging || bladeSelection === blades.none ? '' : 'resizing']"
      :style="`width: ${bladeWidth}px; max-width:40vw;  min-width: ${bladeLimit.sizes?.min}px;`">
      <!-- ${bladeLimit.max}px; -->
      <div class="flex flex-col flex-grow relative">

        <template v-if="bladeSelection === blades.notes">
          <KeepAlive>
            <NotesBlade />
          </KeepAlive>
        </template>

        <template v-else-if="bladeSelection === blades.admin">
          <KeepAlive>
            <AdminBlade />
          </KeepAlive>
        </template>

        <template v-else-if="bladeSelection === blades.profile">
          <KeepAlive>
            <ProfileBlade />
          </KeepAlive>
        </template>

        <!-- draggable button for resizing-->
        <button v-if="bladeSelection !== blades.none && (bladeLimit.sizes.min !== bladeLimit.sizes.max)"
                class="absolute z-50 -right-1 top-0 h-full w-[8px] cursor-col-resize"
                @mousedown="dragStart"
                @mouseup="dragend">
        </button>
      </div>
    </div>
  </div>

</template>
<script>
// this is the edgiest component name I could imagine. *Insert ascii art of a dragon scimitar here*

import NotesBlade from "./NotesBlade.vue";
import AdminBlade from "./AdminBlade.vue";
import ProfileBlade from "./ProfileBlade.vue";
export default {
  name: "TheBlade",
  components: {
    NotesBlade, AdminBlade, ProfileBlade,
  },
  props: {},
  data () {
    let defaultConfig = [
      {
        key: "admin",
        width: 326,
      },
      {
        key: "notes",
        width: 800,
      },
      {
        key: "profile",
        width: 326,
      }, {
        key: "none",
        width: 0,
      }

    ];
    let limits = [
      {
        key: "admin",
        sizes: { min: 300, max: 300 },
      },
      {
        key: "notes",
        sizes: { min: 512, max: 600 },
      },
      {
        key: "profile",
        sizes: { min: 300, max: 300 },
      },

    ];
    let config = defaultConfig;
    const blades = { admin: 0, notes: 1, profile: 2, none: 999 };
    let storedConfig = window.localStorage.getItem(`bladeConfig:${this.$store.getters.getUserId}`);
    if (storedConfig !== null) {
      config = JSON.parse(storedConfig);
    } if (!config) {
      config = defaultConfig;
    }
    return {
      config,
      limits,
      blades,
      keys: Object.keys(blades),
      isDragging: false,
      windowWidth: window.innerWidth
    };
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  unmounted () {
    window.removeEventListener('resize', this.onResize);
    document.removeEventListener('mousemove', this.dragging);
  },
  computed: {
    isUserScribe () {
      if (!this.$store.getters.getUserGroups) return false;
      return this.$store.getters.getUserGroups.findIndex(x => x === "Scribe") !== -1;
    },
    // special feature flag to enable showing the scribe the new app
    showScribeBlade () {
      return this.$store.getters.getFeatureFlags.showScribeNewApp;
    },
    bladeWidth () {
      if (this.bladeSelection == this.blades.none) return 0;
      if (this.bladeSelection == undefined) return 0;
      return this.config[this.bladeSelection].width;
    },
    bladeSelection () {
      let b = this.$route.meta.blade;
      if (b === undefined) return this.blades.none;
      // Special edge case for the scribes just staying on the old view
      else if (b === this.blades.notes && this.isUserScribe && !this.showScribeBlade) return this.blades.none;
      else return this.$route.meta.blade;
    },
    bladeLimit () {
      if (this.bladeSelection === this.blades.none) {
        return {
          key: "none",
          sizes: { min: 0, max: 0 },
        };
      }
      return this.limits[this.bladeSelection];
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth;
    },
    dragging (e) {
      if (this.bladeSelection == this.blades.none) return 0;
      if (this.bladeSelection == undefined) return 0;
      let conf = this.config[this.bladeSelection];
      let lim = this.limits[this.bladeSelection];
      // clamping to [min,max]
      conf.width = Math.min(Math.max(e.clientX - 90, lim.sizes.min), lim.sizes.max);
    },
    dragStart (e) {
      this.isDragging = true;
      document.addEventListener('mousemove', this.dragging);
      document.addEventListener('mouseup', this.dragEnd);

    }, dragEnd (e) {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.dragging);
      document.removeEventListener('mouseup', this.dragEnd);
      this.saveConfig();
    },
    saveConfig () {
      window.localStorage.setItem(`bladeConfig:${this.$store.getters.getUserId}`, JSON.stringify(this.config));
    }

  }
};
</script>
<style>
.blade {
  display: flex;
  flex-direction: column;
  transition-property: width, min-width max-width;
  transition-duration: 150ms;
}

.blade.resizing {
  transition-property: none;
  transition-duration: 0s;
}

.blade-header {

  height: var(--header-h);
}
</style>