<template>
  <transition name="fade">
    <div v-if="pending" class="pending-cover fixed w-full bg-black bg-opacity-25 z-10 default-fade-duration transition-opacity">
      <div class="fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <transition name="fade" appear delay="150ms">
          <div class="flex flex-col bg-white border-main-alt border p-6 rounded-xl transition-opacity default-fade-duration">
            <span class="text-xl font-bold text-center">
              We're setting up  <br /> your account!
            </span>
            <div class="mx-auto my-4">
              <img src="@/assets/images/bird-animated200x200.gif" class="rounded-md" />
            </div>
            <!-- <BaseLoading /> -->
          </div>
        </transition>
      </div>
    </div>
  </transition>
  <div class="relative  px-6 sm:px-10 pt-6 sm:pt-16 flex flex-col bg-white min-h-[60vh] rounded-t-md lg:rounded-t-0">
    <div class="rounded-t-md lg:rounded-none">
      <div class="flex flex-col 4xl:max-w-[1542px] ">

        <div class="flex justify between">
          <div class="flex flex-col xl:basis-2/3 bg-[#F5F6F8] rounded-xl p-12">

            <div class="flex flex-grow justify-between pt-2 ">
              <div class="text-4xl font-montserrat text-neutral-darker font-extrabold my-auto">
                Welcome back to Talkatoo!
              </div>
            </div>
            <div class="flex justify between">
              <div class="flex flex-col flex-grow ">
                <div class=" block pt-6 pb-7 sm:pr-[20%] text-neutral font-roboto text-sm">
                  We've been working our tail feathers off to give you more ways to save time!
                  Just wait until you see what we've been working on. You're going to love it.

                </div>
                <div class="flex flex-col sm:flex-row ">
                  <div class="block w-[190px] mb-4  mx-auto sm:ml-0 sm:mr-4 sm:mb-0 ">
                    <!-- bg-rare2 hover:bg-rare2-dark   transition-colors-->
                    <button class="btn-primary duration-300
                                py-4 rounded-full my-auto w-full font-bold text-xs"
                            @click="toggleSubscriptionModal">
                      Get Started Now!
                    </button>
                  </div>
                  <div class="block w-[190px] mx-auto sm:mx-0">
                    <!-- class="as-button bg-white duration-150 hover:bg-blue-100 transition-colors no-underline -->
                    <a
                      class="block mx-auto no-underline text-neutral hover:text-neutral text-center
                           border-neutral-dark border whitespace-nowrap
                             py-4 rounded-full my-auto w-full font-bold text-xs"
                      href="https://talkatoo.com/lunch-and-learn-referral/" target="_blank">
                      Book a Training Call
                    </a>
                  </div>

                </div>
              <!-- upsell-gradient -->

              </div>
            </div>
          </div>

          <img src="@/assets/images/ai_visits_the_vet.jpeg" class="hidden basis-1/3 xl:block h-[293px]  rounded-xl ml-5" />
        </div>
      </div>

    </div>

    <div class="mt-4 flex flex-col w-full 4xl:max-w-[1542px]">
      <div class="flex flex-col w-full">
        <!-- upsell-gradient -->
        <div class="text-3xl font-montserrat text-neutral-darker text-center mt-4 mb-6 font-extrabold ">
          Check out our latest AI innovations.
        </div>
        <div class="flex flex-col md:flex-row justify center min-h-[18rem] w-full">

          <div class="flex flex-col md:w-1/3 h-full center flex-grow">
            <div class="flex flex-col flex-grow h-full bg-white border border-neutral-lighter rounded-xl
                        pl-2 sm:pl-4 lg:pl-6 p-6 mb-2 md:mb-0 md:mr-3 lg:mr-5">
              <img src="@/assets/images/soap_sliders.gif" class="h-[93px] w-[93px] mx-auto" />
              <ul class="my-auto text-xs font-normal font-roboto text-neutral">
                <li class="mb-1">
                  Record an exam or your notes on your phone or computer.
                </li>
                <li class="mb-1">
                  Our AI takes the transcript and formats it as a SOAP.
                </li>
                <li class="">
                  You can customize the SOAP exactly how you need.
                </li>
              </ul>
              <div class="flex justify-center mt-4 sm:mt-auto">
                <a class="underline font-semibold" href="https://talkatoo.com/veterinary-dictation-software/" target="_blank">
                  Learn more</a>
              </div>
            </div>
          </div>
          <div class="flex flex-col md:w-1/3 h-full center flex-grow">

            <div class="flex flex-col flex-grow bg-white border border-neutral-lighter rounded-xl
                        pl-2 sm:pl-4 lg:pl-6 p-6  mb-2 md:mb-0 md:mr-3 lg:mr-5">
              <img src="@/assets/images/wand_sparker.gif" class="h-[93px] w-[93px] mx-auto" />
              <ul class="my-auto text-xs font-normal font-roboto text-neutral">
                <li class="mb-1">
                  Create client communications from a SOAP in an instant.
                </li>
                <li class="mb-1">
                  Translate text between languages, or format information.
                </li>
                <li class="">
                  Knowledgeable on veterinary terms and practices.
                </li>
              </ul>

              <div class="flex justify-center mt-4 sm:mt-auto">
                <a class="underline font-semibold" href="https://talkatoo.com/auto-soap/" target="_blank">
                  Learn more</a>
              </div>
            </div>
          </div>
          <div class="flex flex-col md:w-1/3 h-full center flex-grow">

            <div class="flex flex-col flex-grow bg-white border border-neutral-lighter rounded-xl
                        mb-12 md:mb-0 pl-2 sm:pl-4 lg:pl-6 p-6">
              <img src="@/assets/images/dictation_shaker.gif" class="h-[93px] w-[93px] mx-auto" />
              <ul class="my-auto text-xs font-normal font-roboto text-neutral">
                <li class="mb-1">
                  More accurate, veterinarian specific speech recognition.
                </li>
                <li class="mb-1">
                  Insert templates and paragraphs copy Talkatext.
                </li>
                <li class="">
                  My word! "My Words" is so much more powerful.
                </li>
              </ul>
              <div class="flex justify-center mt-4 sm:mt-auto">
                <a class="underline font-semibold" href="https://support.talkatoo.com/knowledge/talkatoo-dictation-assistant-tada" target="_blank">
                  Learn more</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <UpdateModals v-if="userPerms.billing_management"
                :loading="loading"
                :productList="productList"
                :subscription="subscription"
                :countryList="countryList"

                :subscriptionModalDisplay="subscriptionModalDisplay"
                :billingModalDisplay="billingModalDisplay"
                :threeDSecureUpgradeModalDisplay="threeDSecureUpgradeModalDisplay"

                @subscriptionUpdate="subscriptionUpdate"
                @toggleSubscriptionModal="toggleSubscriptionModal"
                @toggleBillingModal="toggleBillingModal"
                @toggleThreeDSecureModal="toggleThreeDSecureModal"
                @pendingRequest="pendingRequest"
                @setLoading="setLoading"
  />
</template>

<script>
import helpers from "Modules/SubscriptionHelper";
const ProductService = require("Services/ProductService");
import UpdateModals from "Components/forms/UpdateModals.vue";
import BaseLoading from "Components/ui/BaseLoading.vue";
export default {
  name: "ChargifyReactivate",
  components: {
    UpdateModals,
  },

  data () {
    return {
      // based on chargify card types definition
      cardMap: {
        visa: "Visa",
        master: "Mastercard",
        discover: "Discover",
        american_express: "American Express"
      },
      errorMessage: "",
      loading: true,
      pending: false,
      billingInfo: { address: "", address2: "", city: "", zip: "", country: "", state: "", first_name: "", last_name: "", name: "" },
      shippingInfo: { address: "", address2: "", city: "", zip: "", country: "", state: "", first_name: "", last_name: "", name: "" },

      // update Modal properties
      COUNTRY_ISO_MAP: require("@/assets/content/country_iso_map.json"),
      STATE_ISO_MAP: require("@/assets/content/state_iso_map.json"),
      countryList: [],
      subscription: {
        dictation_seats: 0,
        verified_seats: 0,
        product: { number_of_users: 1 },
        products: [
        ],
        payment_profile: { billing_address: { address: "", address2: "", city: "", zip: "", country: "", state: "", first_name: "", last_name: "", name: "" }, },
        shipping_address: { address: "", address2: "", city: "", zip: "", country: "", state: "", first_name: "", last_name: "", name: "" },
        currency: "usd",
      },
      subProduct: {},
      userComponentsPP: {},
      verifiedComponentsPP: {},
      productList: [],
      subscriptionModalDisplay: open === "upgrade",
      billingModalDisplay: open === "billing",
      shippingModalDisplay: open === "shipping",
      threeDSecureUpgradeModalDisplay: false,
    };
  },
  created () {
    // no stripe subs allowed
    if(this.$store.getters.getBillingProvider == 'stripe') this.$router.push('/');

    this.countryList = helpers.setCountryList(this.COUNTRY_ISO_MAP, this.STATE_ISO_MAP);

    ProductService.GetProducts()
      .then(resp => {
        this.productList = resp.data.products;
        // seed the subscription with an initial product
        let product = this.productList.find(x => x.prices.find(y => y.handle == "retail_monthly"));
        let productPrice = product.prices.find(x => x.handle == "retail_monthly");
        productPrice.price_id = productPrice.id;
        this.subscription.products.push(productPrice);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  beforeUnmount () {
    clearInterval(this.interval);
  },
  computed: {
    userPerms () {
      return this.$store.getters.getUserPerms;
    },
  },
  methods: {
  setLoading (loading) {
      this.loading = loading;
    },
    pendingRequest () {
      this.pending = !this.pending;
    },
    toggleSubscriptionModal () {
      this.subscriptionModalDisplay = !this.subscriptionModalDisplay;
    },
    toggleBillingModal () {
      this.billingModalDisplay = !this.billingModalDisplay;
    },
    toggleThreeDSecureModal () {
      this.shippingModalDisplay = !this.shippingModalDisplay;
    },
    subscriptionUpdate () {
      this.$router.push('/');
    }
  }

};
</script>

<style scoped>
.upsell-gradient.reverse {
  background: linear-gradient(90deg, rgb(90, 122, 244),rgb(3, 144, 193) 50%, rgb(3, 193, 152) );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
.upsell-gradient {
  background: linear-gradient(90deg, rgb(3, 144, 193), rgb(3, 193, 152) 50%, rgb(90, 122, 244));
  /* linear-gradient(0.25turn, #1b9f81, #0386b7, #5273e2); */
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}


.pending-cover {
  height: calc(100vh - var(--header-h));
  width: calc(100vw - var(--sidebar-w));
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
</style>