const ProductService = require("Services/ProductService");
import BaseModal from "Components/ui/BaseModal.vue";
import BaseSlider from "Components/ui/BaseSlider.vue";
import BaseLoading from "Components/ui/BaseLoading.vue";
import BirdsOnAWire from "Components/ui/BirdsOnAWire.vue";
import BillingInfoPreview from "../BillingInfoPreview.vue";
import StripeForm from "Components/forms/BillingModal/StripeForm/StripeForm.vue";
import ShippingForm from "Components/forms/ShippingModal/ShippingForm.vue";

// this.userCountMap[this.sliderVal] - 1
export default {
  name: "StripeSubscriptionModal",
  components: { BaseModal, BaseSlider, BaseLoading, BirdsOnAWire, BillingInfoPreview, StripeForm, ShippingForm },
  props: {
    toDisplay: Boolean,
    productList: Array,
    subscription: Object,
    updatePayment: Boolean,
    countryList: Array,
    upgrade: Boolean
  },

  data () {
    let dictationSeatCount = parseInt(this.subscription.dictation_seats);
    let verifiedSeatCount = parseInt(this.subscription.verified_seats);
    let selectedDictationSeats = dictationSeatCount + 1;
    // we can default to { handle: '' } for any product without a pricepoint, which will be treated as standard
    let standardToggle = true;
    let subRetailProduct = this.subscription.products.find(x => x.handle.match('retail'));
    if (subRetailProduct) {
      standardToggle = subRetailProduct.handle.match('month') !== null;
    }
    return {
      title: "Choose Your Plan",
      loading: false,
      // modal states for controlling the view
      planChange: true,
      confirmStep: false,
      billingPreview: false,
      paymentUpdate: !!this.updatePayment,
      shippingUpdate: false,

      shippingInfo: this.subscription.shipping_address ?? {},
      billingInfo: this.subscription.payment_profile.billing_address,
      // toggle bool between standard and annual premium plan
      standardToggle: standardToggle,
      disableStandardToggle: false,
      // variables for controlling user/verified seat selection
      selectedDictationSeats: selectedDictationSeats,
      minDictationSeats: dictationSeatCount,
      maxDictationSeats: 16,
      maxVerifiedSeats: 16,
      showVerifiedSeatsSelector: verifiedSeatCount > 0,
      selectedVerifiedSeats: verifiedSeatCount,
      minVerifiedSeats: verifiedSeatCount,
      //billing token generated for plan update w/ credit card update
      stripeToken: "",
      sameAsBilling: true,
      enableCheckout: false,
      fastTrack: false,
      // used exclusively for when a chargify sub is being reactivated
      savedShippingData: undefined,
    };
  },
  created () {
    // handling url redirects through
    if (this.upgrade) {
      if (this.upgrade == "fast") { // todo get a better keyword
        this.fastTrackUser();
      }
    }
  },
  watch: {
    subscription (newVal) {
      this.shippingInfo = newVal.shipping_address;
      // this can occur when shipping info updates on a trialing account
      if (newVal.payment_profile.billing_address !== undefined && newVal.payment_profile.billing_address !== null) {
        this.billingInfo = newVal.payment_profile.billing_address;
      }
    }
  },
  computed: {
    showVerified () {
      if (this.subscription.currency !== 'usd')
        return false; // we only have a usd priced verified product
      if (this.minVerifiedSeats > 0) {
        return true;
      }
      return true;
    },
    isVerifiedUpgrade () {
      return this.showVerifiedSeatsSelector && (this.selectedVerifiedSeats > 0);
    },
    subDictationSeats () {
      return parseInt(this.subscription.dictation_seats);
    },
    subVerifiedSeats () {
      return parseInt(this.subscription.verified_seats);
    },
    // the price per user in an integer form. Helps with formatting and usability
    dictationSeatPrice () {
      let retailName = this.standardToggle ? "retail_monthly" : "retail_annual";

      let product = this.productList.find(x => x.prices.find(y => y.handle == retailName));
      let productPrice = product.prices.find(x => x.handle == retailName);
      let finalPrice = productPrice;
      if (this.subscription.currency !== "usd") {
        finalPrice = productPrice.additional_currencies.find(x => x.currency == this.subscription.currency);
      }
      // special zero up_to_amount to represent infinity
      let seatCount = this.selectedDictationSeats > 15 ? 0 : this.selectedDictationSeats;
      let tier = finalPrice.tiers.find(x => x.up_to_amount == seatCount);
      return parseInt(tier.price_in_cents) / 100;
    },
    // creates displayable Price per user per month based on user count selected. No cents
    dictationSeatMonthlyPriceFormatted () {
      if (this.selectedDictationSeats === 0) return "$0.00";
      let price = this.standardToggle ? this.dictationSeatPrice : this.dictationSeatPrice / 12;
      let val = this.$store.getters.currencyFormatter.format(price);
      return price % 1 !== 0 ? val : val.slice(0, -3);
    },
    verifiedSeatPrice () {
      let productPrice = this.productList.find(x => x.prices.find(y => y.handle.match("verified_monthly")))
        .prices.find(x => x.handle == "verified_monthly");
      return parseInt(productPrice.price_in_cents) / 100;
    },
    verifiedSeatPriceFormatted () {
      return this.$store.getters.currencyFormatter.format(parseInt(this.verifiedSeatPrice));
    },
    // used to display a product's total price for the modal
    productPrice () {
      let dictationPrice = (this.selectedDictationSeats * this.dictationSeatPrice);
      if (dictationPrice % 1 > 0.98) dictationPrice += (1 - dictationPrice % 1); // round any floating point errors I guess
      let verifiedPrice = this.selectedVerifiedSeats * this.verifiedSeatPrice;
      let finalVal = dictationPrice + verifiedPrice;
      if (finalVal % 1 === 0) return finalVal;
      return finalVal.toFixed(2);
    },
    // used to display a product's description for the modal
    productUserDescription () {
      return `${this.selectedDictationSeats} Users - ${this.standardToggle ? "Monthly" : "Annual"}`;
    },
    productVerifiedDescription () {
      return `${this.selectedVerifiedSeats} Verified Seat${this.selectedVerifiedSeats === 1 ? '' : 's'}`;
    },
    billingPeriod () {
      return this.standardToggle ? "month" : "annual";
    },
    pricePointHandle () {
      return this.standardToggle ? "monthly" : "annual";
    },
    // checks the conditions required to make a credit card valid
    // payment == card && card exists && not expired
    cardValid () {
      if (this.subscription.payment_profile.payment_type === "card" && this.subscription.payment_profile.masked_card_number != '') {
        const expiry = new Date(this.subscription.payment_profile.expiration_year, parseInt(this.subscription.payment_profile.expiration_month) - 1, 1);
        return expiry.getTime() > new Date().getTime();
      } else {
        return false;
      }
    },
    //determines if a user will be required to update their billing information
    billingUpdateRequired () {
      return (!this.cardValid);
    },
    // determines if the current subscription's plan is a premium plan
    isSubPremiumPlan () {
      if (!this.subscription?.product?.price_points) return false;
      let pp = this.subscription?.product?.price_points[0];
      if (pp) {
        // leaving premium here for historic plans
        return pp.handle === "premium" || pp.handle === "annual";
      }
      // on (double) old plans and now new plans, if we don't have price points (we will) then this fails on annual plans
      return false;
    },
    finalProducts () {
      // string price_id = 1; // price id in stripe
      // string handle = 2; // handle of the price in chargify
      // string product_id = 5; // product id in stripe
      // string name = 10; // name of the product
      // string description = 15; // description of the product
      // int64 quantity = 20; // quantity of the product
      let arr = [];
      // standardToggle === true === monthly
      let userSeatProduct, verifiedSeatProduct;
      let retailName = this.standardToggle ? "retail_monthly" : "retail_annual";
      let dictationProduct = this.productList.find(x => x.prices.find(y => y.handle == retailName));
      let dictationPrice = dictationProduct.prices.find(x => x.handle.match(retailName));

      userSeatProduct = {
        price_id: dictationPrice.id,
        handle: dictationPrice.handle,
        product_id: dictationProduct.product_id,
        quantity: this.selectedDictationSeats,
      };

      arr.push(userSeatProduct);
      if (this.selectedVerifiedSeats) {
        let verifiedPrice = this.productList.find(x => x.prices.find(y => y.handle.match("verified_monthly")))
          .prices.find(x => x.handle == "verified_monthly");
        // let verifiedProduct = this.productList.filter(x => x.prices.find(y => y.handle.match("verified_monthly")));
        // let verifiedPrice = verifiedProduct.prices.find(x => x.handle.match("verified_monthly") /* && x.currency == this.subscription.currency */);
        verifiedSeatProduct = {
          price_id: verifiedPrice.id,
          handle: "verified_monthly",
          product_id: verifiedPrice.product_id,
          quantity: this.selectedVerifiedSeats,
        };
        arr.push(verifiedSeatProduct);
      }
      return arr;
    },
    isSelectionCurrentPlan () {
      if (this.selectedDictationSeats == this.minDictationSeats
        && this.selectedVerifiedSeats == this.minVerifiedSeats) {
        // no seat change,but maybe there is a monthly to annual plan
        const subRetailProduct = this.subscription.products.find(x => x.handle.match('retail'));
        const isMonthlyPlan = subRetailProduct?.handle.match('month') != undefined;
        if (subRetailProduct && isMonthlyPlan != this.standardToggle) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  methods: {
    toggleModal () {
      this.statePlanSelection();
      if (this.toDisplay) {
        this.showVerifiedSeatsSelector = this.minVerifiedSeats !== 0;
        this.disableStandardToggle = this.showVerifiedSeatsSelector;
      } else {
        this.showVerifiedSeatsSelector = false;
        this.disableStandardToggle = false;
        this.selectedDictationSeats = this.minDictationSeats;
        this.selectedVerifiedSeats = this.minVerifiedSeats;
      }
      this.$emit("subscriptionToggleEvent", this.toDisplay);
    },
    // used to forward right to checkout when a user is sent an upgrade screen
    fastTrackUser () {
      this.fastTrack = true;
      this.stateConfirmStep();
    },
    // this handles the event of when a user selects a plan and will make the decision
    // to either prompt them to update their payment information or not depending on if it exists
    selectedPlan () {
      if (this.billingUpdateRequired) {
        this.statePaymentUpdate();
      } else {
        this.statePreviewBilling();
      }
    },

    updateStripeToken (event) {
      this.stripeToken = event.token;
      this.billingInfo = event.billingInfo;
      // in place billing update, this'll update the billing information to use elsewhere and if a user goes back they won't have to re-input the information
      // cannot do this for trialing accounts, it would mean they suddenly become paid which may not be intended
      if (this.$store.getters.isActive) {
        this.loading = true;
        this.stripeToken = event.token;
        this.$emit("updateBillingEvent", { stripeToken: this.stripeToken, noToggle: true });
        // token used once
        this.stripeToken = "";
      }
      this.loading = false;
      this.stateShippingStep();
    },

    updateShippingInfo (event) {
      let shippingData = {
        shippingInfo: event.shippingInfo,
        noToggle: true
      };
      // when a user explicitly changes shipping
      // in case we're dealing with a chargify reactivation we will post shipping data once the sub is updated.
      if (this.$store.getters.getBillingProvider == 'chargify') {
        this.savedShippingData = shippingData;
      } else if (event.change) {
        this.$emit("updateShippingEvent", shippingData);
        // the user has left the form untouched and used billing info
      } else if (this.subscription.shipping_address.address === "") {
        let shippingData = {
          shippingInfo: this.shippingInfo, //populated from billing info
          noToggle: true
        };
        this.$emit("updateShippingEvent", shippingData);
      }

      if (this.fastTrack) {
        this.fastTrack = false;
        this.stateConfirmStep("Complete Your Purchase");
      } else {
        this.stateConfirmStep();
      }
    },

    // For shipping form, copies billing info to shipping info
    toggleSameAsBilling () {
      if (this.sameAsBilling) {
        this.shippingInfo = this.billingInfo;
      } else {
        this.shippingInfo = this.subscription.shipping_address;
      }
    },

    statePlanSelection () {
      this.title = "Choose Your Plan";
      this.planChange = true;
      this.billingPreview = false;
      this.paymentUpdate = false;
      this.shippingUpdate = false;
      this.confirmStep = false;
    },

    statePreviewBilling () {
      this.title = "Billing Information";
      this.planChange = false;
      this.billingPreview = true;
      this.paymentUpdate = false;
      this.shippingUpdate = false;
      this.confirmStep = false;
    },

    statePaymentUpdate () {
      this.title = "Update Payment Information";
      this.planChange = false;
      this.billingPreview = false;
      this.paymentUpdate = true;
      this.shippingUpdate = false;
      this.confirmStep = false;
    },

    stateShippingStep () {
      // set shipping info == billing info if there's no billing info
      if (this.subscription.shipping_address.address === "") {
        this.shippingInfo = this.billingInfo;
      } else {
        this.sameAsBilling = false;
      }
      this.title = "Shipping Information";
      this.planChange = false;
      this.billingPreview = false;
      this.paymentUpdate = false;
      this.shippingUpdate = true;
      this.confirmStep = false;
    },

    stateConfirmStep (title) {
      this.title = title ?? "Review Changes";
      this.planChange = false;
      this.billingPreview = false;
      this.paymentUpdate = false;
      this.shippingUpdate = false;
      this.confirmStep = true;
    },

    // prevent annual plan selection while a user has verified seats
    guardAnnualSelection (e) {
      if (this.showVerifiedSeatsSelector) {
        this.standardToggle = true;
      }
    },

    // * actions for the dictation seat add/remove fields
    removeDictationSeat () {
      if (this.selectedDictationSeats === 1) return;

      if (this.selectedDictationSeats > this.subDictationSeats) {
        this.selectedDictationSeats--;
      }
      //this shouldn't happen
      else if (this.selectedDictationSeats < this.subDictationSeats) {
        this.selectedDictationSeats = this.subDictationSeats;
      }
    },
    addDictationSeat () {
      if (this.selectedDictationSeats < this.maxDictationSeats) {
        this.selectedDictationSeats++;
      } else if (this.selectedDictationSeats > this.maxDictationSeats) {
        this.selectedDictationSeats = this.subDictationSeats;
      }
    },
    // event handler for input box changes
    dictationSeatChange () {
      if (this.selectedDictationSeats === 0) this.selectedDictationSeats ++;
      if (this.selectedDictationSeats > this.maxDictationSeats) {
        this.selectedDictationSeats = this.maxDictationSeats;
      }
      if (this.selectedDictationSeats < this.subDictationSeats) {
        this.selectedDictationSeats = this.subDictationSeats;
      }
    },
    dictationInputGuard (e) {
      if (this.selectedDictationSeats === '') this.selectedDictationSeats = this.minDictationSeats;
    },

    // * actions for the verified seat add/remove fields
    removeVerifiedSeat () {
      if (this.selectedVerifiedSeats > this.subVerifiedSeats) {
        this.selectedVerifiedSeats--;
      }
      //this shouldn't happen
      else if (this.selectedVerifiedSeats < this.subVerifiedSeats) {
        this.selectedVerifiedSeats = this.subVerifiedSeats;
      }
    },
    addVerifiedSeat () {
      this.selectedVerifiedSeats++;
    },
    addVerified () {
      this.standardToggle = true;
      this.showVerifiedSeatsSelector = true;
    },
    verifiedSeatChange () {
      if (this.selectedVerifiedSeats > this.maxVerifiedSeats) {
        this.selectedVerifiedSeats = this.maxVerifiedSeats;
      }
      if (this.selectedVerifiedSeats < this.minVerifiedSeats) {
        this.selectedVerifiedSeats = this.minVerifiedSeats;
      }
    },
    verifiedInputGuard () {
      if (this.selectedVerifiedSeats === '') this.selectedVerifiedSeats = this.minVerifiedSeats;
    },

    billingFormCancel () {
      if (this.$store.getters.isActive) {
        this.statePreviewBilling();
      } else {
        this.statePlanSelection();
      }
    },

    confirmUpgrade () {
      this.loading = true;
      this.$emit("upgradeEvent",
        {
          dictationSeats: this.selectedDictationSeats,
          verifiedSeats: this.selectedVerifiedSeats,
          products: this.finalProducts,
          stripeToken: this.stripeToken,
          shippingData: this.savedShippingData,
        });
      this.toggleModal();
    },
  },
};