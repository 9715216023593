<script setup>
import { ref, defineEmits, nextTick } from "vue";
const emit = defineEmits(["copyClick"]);
let props = defineProps({
  text: {
    type: String,
    default: "Copy",
  },
  target: String, // target to copy from
  reverse: Boolean,
  plaintextSubstitute:String,
});
let copiedAllText = ref(false);
// this is done to allow time for the soap sections to render if they are collapsed to give a target to copy
function delayCopy () {
  setTimeout(copyTextToClipboard, 0);
}
function copyTextToClipboard () {
  let selection = document.querySelector(props.target);
  if (selection.select == undefined) {
    copyRichTextToClipboard(selection);
    return;
  }
  selection.select();
  document.execCommand("copy", false, undefined);
  let windowSelection = window.getSelection();
  windowSelection.setBaseAndExtent(selection, 0, selection, 0);
  animateCopyButton();
  emit("copyClick", { target: props.target });
}

async function copyRichTextToClipboard (selection) {
  try {
    // causes content's formatting to be preserved when pasted into a note
    selection.firstChild.setAttribute("data-pm-slice", "0 0 []");
    await navigator.clipboard.write([
      new ClipboardItem({
        'text/html': new Blob([selection.innerHTML], { type: 'text/html' }),
        'text/plain': new Blob([props.plaintextSubstitute ? props.plaintextSubstitute : selection.innerText], { type: 'text/plain' }),
      })
    ]);
  } catch (err) {
    let range = document.createRange();
    range.selectNode(selection);
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy", false, selection.innerHtml);
    window.getSelection().removeAllRanges();// to deselect
  }
  animateCopyButton();
  emit("copyClick", { target: props.target });
}


let copyTextTimeout = -1;
function animateCopyButton () {
  nextTick(() => copiedAllText.value = true);
  clearTimeout(copyTextTimeout);
  copyTextTimeout = setTimeout(() => copiedAllText.value = false, 1000);
}

</script>
<template>
  <Transition name="fade" appear>
    <div class="flex duration-200">
      <div class="flex flex-col justify-center my-auto">
        <Transition name="fade-pull" appear>
          <div v-if="copiedAllText" class="z-50 flex justify-center select-none text-rare2 ">
            <div class="absolute flex translate-y-[-120%] bg-white z-50  p-2 rounded-lg font-bold">
              Copied!
            </div>
          </div>
        </Transition>
        <div class="block">
          <slot>
            <button :class="['flex rounded-full py-2 px-3 select-none text-xs transition-colors',
                             'hover:bg-gray-100 hover:bg-opacity-25',
                             reverse ? 'flex-row-reverse' : '']"
                    @click="delayCopy"
            >
              <div class=" my-auto hidden xs:block whitespace-nowrap">
                {{ text }}
              </div>
              <div :class="['material-icons block text-sm', reverse ? 'mr-1' : 'ml-1']">
                copy_all
              </div>

            </button>
            <!-- <button class="flex select-none text-xs transition-colors hover:bg-gray-700 hover:bg-opacity-25 rounded-lg px-2 py-1"
                    @click="copyTextToClipboard">
              <div class="material-icons block text-sm mt-0.5 mr-1">
                copy_all
              </div>
              <div class=" my-auto hidden xs:block whitespace-nowrap">
                {{ text }}
              </div>

            </button> -->
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>
<style scoped>
.fade-pull-enter-active {
  /* transition: all 0.3s ease-out; */
  animation-duration: 0.3s;
  animation-name: slidein;
  animation-iteration-count: 1;
}

.fade-pull-leave-active {
  transition: all 0.3s ease-out;
}

@keyframes slidein {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  40% {
    transform: translateY(1px);
  }

  60% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>