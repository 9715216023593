import SubscriptionService from "Services/SubscriptionService.js";
const PasswordResetService = require("Services/PasswordResetService");
const ProductService = require("Services/ProductService");
import MixpanelService from "Services/MixpanelService";
import helpers from "Modules/SubscriptionHelper";
import UserTable from "./UserTable";
import BaseLoading from "Components/ui/BaseLoading";
import BaseIcon from "Components/ui/BaseIcon";
import BaseModal from "Components/ui/BaseModal";
import TableLabel from "./TableLabel.vue";
import InviteModal from "./InviteModal.vue";
import UpdateModals from "Components/forms/UpdateModals.vue";
import StandardTemplate from "@/components/layout/StandardTemplate.vue";

export default {
  name: 'UserManagement',
  components: { UserTable, BaseLoading, BaseIcon, BaseModal, InviteModal, UpdateModals, StandardTemplate },
  data () {
    const queryParams = this.$store.getters.getLandingData.query;
    if (queryParams) {
      // user is back on the page after a successful redirect from 3ds authorization
      if (queryParams.threeds_success === "true") {
        MixpanelService.Track("AdminPortal:3dsSuccess", {
          "subscription_id": this.$store.getters.getSubscriptionId,
          "page": "Invitation"
        });
        this.$toast.success({ message: "Congratulations on your new subscription! You'll find all your updated subscription information below" });
      }
      this.$store.commit("clear_landing");
    }
    return {
      loading: true,
      // async loading for products
      loadingProduct: true,

      //* data structures for holding
      users: [],
      invites: [],
      subscription: undefined,

      tableData: [],
      colDef: {},

      showDeleteModal: false,
      showResetModal: false,
      howPermissionModal: false,
      inviteModalDisplay: false,

      //* booleans for enabling and disabling modal actions on click and pending request
      removePending: false,
      resetPending: false,
      permUpdatePending: false,
      //* set of permission objects to store permission assignment state in the modal
      modalPermissions: [],
      permissionKeys: [],
      //* will always be a subset of tableData
      selectedUsers: [],

      errorMessage: "",
      callToAction: undefined,

      //* upgrade modal properties
      COUNTRY_ISO_MAP: require("@/assets/content/country_iso_map.json"),
      STATE_ISO_MAP: require("@/assets/content/state_iso_map.json"),
      billingInfo: { address: "", address2: "", city: "", zip: "", country: "", state: "", first_name: "", last_name: "" },
      countryList: [],
      subscriptionModalDisplay: false,
      threeDSecureUpgradeModalDisplay: false,
      productList: undefined,
    };
  },
  computed: {
    userPerms () {
      return this.hidePermsForVerified(this.$store.getters.getUserPerms);
    },
    numberOfUsers () {
      return this.subscription.dictation_seats;
    },
    subHasDictationSeats () {
      if (!this.subscription) return false;
      return this.subscription.dictation_seats - this.subscription.number_of_dictation_users !== 0;
    },
    subHasVerifiedSeats () {
      if (!this.subscription) return false;
      return this.$store.getters.subHasVerifiedSeats;
    },
    dictationUsers () {
      return this.tableData.filter(x => x.permissionsObj.dictation);
    },
    managerUsers () {
      return this.tableData.filter(x => x.permissionsObj.user_management && x.permissionsObj.billing_management);
    },
    verifiedUsers () {
      return this.tableData.filter(x => x.permissionsObj.verified);
    },
    editorUsers () {
      return this.tableData.filter(x => x.permissionsObj.editor);
    },
    nonInviteManagerUsers () {
      return this.managerUsers.filter(x => !x.inviteToken);
    },
    nonInviteSelectedUsers () {
      return this.selectedUsers.filter(x => !x.inviteToken);
    },
    selectedInvites () {
      return this.selectedUsers.filter(x => !!x.inviteToken);
    },
    verifiedSeatsCount () {
      let count = 0;
      return parseInt(this.subscription.verified_seats);
    },
    dictationSeatFillPercent () {
      if (this.subscription.dictation_seats === 0) return 0;
      if (this.numberOfUsers === 0) return 0;
      let fraction = (this.dictationUsers.length / parseInt(this.numberOfUsers));
      if (fraction > 1) return 100;
      return fraction * 100;
    },
    verifiedSeatFillPercent () {
      if (this.verifiedSeatsCount === 0) return 0;
      let fraction = this.verifiedUsers.length / this.verifiedSeatsCount;
      if (fraction > 1) return 100;
      return fraction * 100;
    },
    // for displaying the delta of dictation users on the permissions change modal
    dictationUserResult () {
      if (this.modalPermissions.length == 0) {
        return 0;
      }
      let currCount = this.dictationUsers.length;
      if (this.modalPermissions.find(x => x.name == "dictation").val) {
        this.selectedUsers.forEach(x => currCount += !x.permissionsObj.dictation);
      } else {
        this.selectedUsers.forEach(x => currCount -= x.permissionsObj.dictation);
      }
      return currCount;
    },
    // for displaying the delta of dictation users on the permissions change modal
    verifiedUserResult () {
      if (this.modalPermissions.length == 0 || !this.subHasVerifiedSeats) {
        return 0;
      }
      let currCount = this.verifiedUsers.length;
      if (this.modalPermissions.find(x => x.name == "verified").val) {
        this.selectedUsers.forEach(x => currCount += !x.permissionsObj.verified);
      } else {
        this.selectedUsers.forEach(x => currCount -= x.permissionsObj.verified);
      }
      return currCount;
    },
    // for displaying the delta of full-access managers on the permissions change user modal
    managerUserResult () {
      if (this.modalPermissions.length == 0) {
        return 0;
      }
      let currCount = this.managerUsers.length;
      if (this.modalPermissions.find(x => x.name == "user_management").val && this.modalPermissions.find(x => x.name == "billing_management").val) {
        this.selectedUsers.forEach(x => currCount += !(x.permissionsObj.user_management && x.permissionsObj.billing_management));
      } else {
        this.selectedUsers.forEach(x => currCount -= (x.permissionsObj.user_management && x.permissionsObj.billing_management));
      }
      return currCount;
    },
    // checking if the user has selected themselves to show warnings about changing their permissions
    selfSelected () {
      return undefined !== this.selectedUsers.find(x => x.userId == this.$store.getters.getUserId);
    },
    // business logic check to see if a remove will fail and showing a message about why removing may be disabled
    disableRemove () {
      // calculating if all active managers are selected
      const allUsersSelected = this.tableData.filter(x => x.status !== "invited").length == this.nonInviteSelectedUsers.length;
      // we can shortstop this func here
      if (allUsersSelected) return true;

      const allManagersSelected =
        this.nonInviteManagerUsers.reduce((preset, user) => {
          return preset && this.nonInviteSelectedUsers.find(x => x.userId == user.userId);
        }, true);
      return allManagersSelected;
    },
    invitesRemaining () {
      if (!this.subscription) return 0;
      return this.subscription.number_of_user_seats - (this.users.length + this.invites.length);
    },
  },
  methods: {
    hidePermsForVerified (perms) {
      delete perms.editor;
      if (!this.subHasVerifiedSeats) delete perms.verified;
      return perms;
    },
    handlePageData (resp) {
      this.users = resp[0].data.users;
      this.invites = resp[1].data.invites;
      this.subscription = resp[2].data;
      if (!this.subscription.shipping_address) {
        this.subscription.shipping_address = { address: "", address2: "", city: "", zip: "", country: "", state: "", first_name: "", last_name: "", name: "" };
      }
      if (this.subscription.payment_profile && !this.subscription.payment_profile.billing_address) {
        this.subscription.payment_profile.billing_address = { address: "", address2: "", city: "", zip: "", country: "", state: "", first_name: "", last_name: "", name: "" };
      }
      // ! for removing all permissions when accounts don't have the right settings
      this.users.forEach(x => this.hidePermsForVerified(x.permissions));
      this.invites.forEach(x => this.hidePermsForVerified(x.permissions));
      this.subscription.number_of_users = parseInt(this.subscription.number_of_users);
      this.subscription.number_of_user_seats = parseInt(this.subscription.number_of_user_seats);
      this.subscription.number_of_dictation_users = parseInt(this.subscription.number_of_dictation_users);
      this.subscription.dictation_seats = parseInt(this.subscription.dictation_seats);
      this.subscription.number_of_verified_users = parseInt(this.subscription.number_of_verified_users);
      this.subscription.verified_seats = parseInt(this.subscription.verified_seats);
      this.subscription.pending_invites = parseInt(this.subscription.pending_invites);

      this.buildTable();
      this.loading = false;
    },

    buildTable () {
      // setting a global reference to the set of keys in a permission object
      // subscriptions will always have 1 user
      this.permissionKeys = Object.keys(this.users[0].permissions);
      this.tableData = this.invites.map(el => {
        let dateString = new Date(parseInt(el.issued_unix_seconds) * 1000).toLocaleDateString('en-US', { month: "short", day: "2-digit", year: "numeric" });
        return {
          isInvite: true,
          selected: false,
          clicked: false,
          title: {
            name: "Awaiting Activation",
            email: el.user_email,
          },
          status: "invited",
          permissions: this.commatizeArray(this.parsePermissions(el.permissions)),
          permissionsObj: el.permissions,
          userId: el.invite_token,
          inviteToken: el.invite_token,
          dateString: dateString,
          actionLinkText: {
            text: "Resend",
            success: "Invite Sent!"

          },
          actionLinkIcon: {
            icon: "delete",
          },
        };
      });
      this.tableData = this.tableData.concat(this.users.map(el => {
        return {
          isInvite: false,
          selected: false,
          clicked: false,
          title: {
            email: el.email,
            name: this.parseFullName(el.first_name, el.last_name),
          },
          status: "enabled",
          permissions: this.commatizeArray(this.parsePermissions(el.permissions)),
          permissionsObj: el.permissions,
          userId: el.user_id,
          dateString: "",
          actionLinkIcon: {
            icon: "keyboard_arrow_right",
          },
        };
      }));
      let disableFuncParams = {
        maxDictators: parseInt(this.subscription.dictation_seats),
        maxVerified: parseInt(this.subscription.verified_seats)
      };
      this.colDef = {
        keyField: "userId",
        col: [{
          selectCol: true,
          field: "selected",
          displayName: "",
          colClass: "w-6",
          cellClass: "select",
        },
        {
          field: "title",
          filter: true,
          displayName: "User",
          colClass: "w-48",
          cellClass: "font-semibold lg:pl-4 lg:pt-4 lg:pb-2",
        },
        {
          field: "permissions",
          displayName: "Permissions",
          filter: true,
          colClass: "w-full mr-auto",
          cellClass: "lg:pl-4 lg:py-2 capitalize",
          showDisplayName: true,
          // * dropdown params
          dropdownCol: true,
          items: "permissionsObj",
          // set of params to be passed to disableFunc, can be defined to anything and used as needed in disableFunc
          params: disableFuncParams,
          // Used to enforce business logic

          disableFunc: function (row, data, params, thisUserPerms) {
            // simple disable function that will limit a select list from allowing more than x users with dictation privileges
            // pushing false/true will disable when the value of the permission key is is equal to value pushed
            // eg. pushing false on billing_management will disable check when it is in the false position.
            let disableList = [];
            if (params.maxDictators <= data.filter(x => { return x.permissionsObj.dictation; }).length) {
              disableList.push({ "dictation": false });
            }
            if (params.maxVerified <= data.filter(x => { return x.permissionsObj.verified; }).length) {
              disableList.push({ "verified": false });
            }
            //  if dictation is checked, only let users uncheck verified
            if (row.permissionsObj.dictation && !row.permissionsObj.verified) {
              disableList.push({ "verified": false });
            }
            //  if verified is checked, only let users uncheck dictation
            if (row.permissionsObj.verified && !row.permissionsObj.dictation) {
              disableList.push({ "dictation": false });
            }
            // prevent adding permissions they don't have
            let disableBilling = true;
            if (!thisUserPerms.billing_management) {
              disableBilling = false;
              // disable for both states, no management permission permitted to change to change
              disableList.push({ "billing_management": false }, { "billing_management": true });
            }
            // checking manager rules
            const managers = data.filter(x => x.status == 'enabled' && x.permissionsObj.user_management && x.permissionsObj.billing_management);
            if (managers.length == 1 && managers[0].clicked) {
              disableList.push({ "user_management": true });
              if (disableBilling) {
                disableList.push({ "billing_management": true });
              }
            }
            return disableList;
          }
        },
        {
          field: "status",
          displayName: "Status",
          customComponent (row) {
            return { component: TableLabel, props: { state: row.status, dateString: row.dateString } };
          },
          filter: true,
          colClass: " w-16",
          // cellClass: "font-semibold",
          showDisplayName: true,
        },
        {
          isActionLinkText: true,
          colClass: "pr-2",
          cellClass: "text-main-alt font-roboto font-medium",
        },
        {
          isActionLinkIcon: true,
          actionLinkIcon: { icon: "delete" },
          colClass: " w-4",
        },
        ]
      };
    },

    parseFullName (first, last) {
      if (first !== undefined && last !== undefined) return (first + ' ' + last).trim();
      else if (first !== undefined) return first;
      else if (last !== undefined) return last;

      return "";
    },

    // turns the permissions object into an array of permissions
    parsePermissions (perms) {
      let userPerms = [];
      this.permissionKeys.forEach(key => { if (perms[key]) userPerms.push(key.replace(/_/g, " ")); });
      if (userPerms.length == 0) userPerms.push("None");
      return userPerms;
    },
    // add commas between elements of an array of comma-less strings
    commatizeArray (arr) {
      return arr.reduce((a, b) => a.concat(b).concat(", "), "").slice(0, -2);
    },
    // handler for row-selection event (single row selection)
    //  from the table selected will be inverted from the actual value (hasn't been updated so use inverse)
    rowSelect (row) {
      this.tableData.find(x => x.userId == row.userId).selected = !row.selected;
      this.selectedUsers = this.tableData.filter(x => x.selected);
    },
    // handler for toggle-select-all event from table
    toggleAllSelection (bool, rows) {
      this.tableData.forEach(userRow => {
        if (rows.find(row => row.userId == userRow.userId).selected) userRow.selected = bool;
      });
      this.selectedUsers = this.tableData.filter(x => x.selected);
    },



    // modal toggles
    toggleResetModal () {
      if (this.selectedUsers.length == 0) {
        this.$toast.error({ message: "Select a user to send a password reset" });
        return;
      }
      this.showResetModal = !this.showResetModal;
    },

    togglePermissionModal () {
      if (this.selectedUsers.length == 0) {
        this.$toast.error({ message: "Select a user to update permissions" });
        return;
      }
      this.modalPermissions.length = 0;
      // reducing the set of shared permissions into one object that will be the preset for the checkboxes
      // foreach user, find where the shared permissions exist in their permissions object
      const permissionsPreset = this.selectedUsers
        .reduce((preset, x) => {
          this.permissionKeys.forEach(key => {
            preset[key] = preset[key] && x.permissionsObj[key];
          }, this);
          return preset;
        }, /* preset */ this.permissionKeys.reduce((obj, key) => { obj[key] = true; return obj; }, {}));
      // dynamically setting permission types to present for modification
      this.permissionKeys.forEach(key => {
        this.modalPermissions.push({ val: permissionsPreset[key], name: key });
      });
      this.showPermissionModal = !this.showPermissionModal;
    },

    toggleDeleteModal () {
      if (this.selectedUsers.length == 0) {
        this.$toast.error({ message: "Select a user to remove" });
        return;
      }
      this.showDeleteModal = !this.showDeleteModal;
    },

    toggleInviteModal () {
      this.inviteModalDisplay = !this.inviteModalDisplay;
    },

    //event handler for menu items
    actionItemClick (e) {
      switch (e) {
        case ("reset-passwords"):
          this.toggleResetModal();
          break;
        case ("update-permissions"):
          this.togglePermissionModal();
          break;
        case ("resend-invites"):
          this.resendSelectedInvites();
          break;
        case ("remove-users"):
          this.toggleDeleteModal();
          break;
        default:
          console.error("uncaught event:" + e.event, e);
      }
    },
    tableRowClick (row) {
      if (row.isInvite) return;
      this.$router.push(`/profile/${row.userId}`);

    },
    rowActionClickHandler ({ row, col }) {
      if (col.isActionLinkText) {
        let invitee = [{
          user_email: row.title.email,
          invite_token: row.inviteToken,
        }];
        this.resendInvite(invitee);
        // resend email.
      }
      if (col.isActionLinkIcon) {
        if (row.isInvite) {

          // delete invite.
          this.removeInvite(row.userId);
          let index = this.tableData.findIndex(x => x.title.email == row.title.email);
          this.tableData.splice(index, 1);
        } else {
          this.$router.push(`/profile/${row.userId}`);
        }
      }
    },
    //inviteUsers sends new invitees and adds their emails to the table
    inviteUsers (invitees) {
      this.loading = true;
      return SubscriptionService.CreateInvites(invitees)
        .then(resp => {
          resp.data.invites.forEach(x => this.hidePermsForVerified(x.permissions));
          this.invites = this.invites.concat(resp.data.invites);
          this.buildTable();

          this.$toast.success({ message: "Invite sent." });
          return resp;
        })
        .catch(err => {
          this.$toast.error({ message: "Could not send invitation. " + err?.data?.message ?? " Contact admin!" });
          if (!err?.data?.message) {
            console.log("this is additional logging for debug when the message is undef", this.subscription, invitees);
          }
          console.error(err);
        })
        .finally(() => {
          this.toggleInviteModal();
          this.loading = false;
        });
    },
    resendInvite (invitee) {
      return SubscriptionService.ResendInvites(invitee)
        .then(resp => {
          this.$toast.success({ message: "Invite resent." });
          return resp;
        })
        .catch(err => {
          this.$toast.error({ message: "Could not send invitation. " + err?.data?.message });
          this.tableData.foreach(x => x.disabled = false);
          console.error(err);
        });
    },
    resendSelectedInvites () {
      let invites = [];
      if (this.selectedInvites.length !== 0)
        invites = this.selectedUsers.filter(x => !!x.inviteToken).map(x => ({ user_email: x.title.email, invite_token: x.inviteToken }));
      else {
        invites = this.tableData.filter(x => !!x.inviteToken).map(x => ({ user_email: x.title.email, invite_token: x.inviteToken }));
      }
      return SubscriptionService.ResendInvites(invites)
        .then(resp => {
          this.$toast.success({ message: `${invites.length} Invite${invites.length > 1 ? 's' : ''} resent.` });
          return resp;
        })
        .catch(err => {
          // all or nothing failure
          this.$toast.error({ message: `Could not send invitation${invites.length > 1 ? 's' : ''} ` + err?.data?.message });
          this.tableData.foreach(x => x.disabled = false);
          console.error(err);
        });
    },
    removeInvite (userId) {
      SubscriptionService.DeleteInvite(userId)
        .then(() => {
          //successful request, remove invite from list
          this.invites = this.invites.filter(x => (x.invite_token != userId));
          this.buildTable();
          this.$toast.success({ message: "Invite cancelled." });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateSinglePermissions (row) {
      // create a payload
      let userPermissions = [{ permissions: row.permissionsObj }];
      if (!row.inviteToken) {
        userPermissions[0].user_id = parseInt(row.userId);
      } else { // invite token is used as the id in tableData.
        userPermissions[0].invite_token = row.userId;
      }
      let batch = {
        subscription_id: this.$store.getters.getSubscriptionId,
        user_permissions: userPermissions
      };
      SubscriptionService.UpdatePermissions(batch)
        .then((resp) => {
          this.users = resp.data.users;
          if (batch.user_permissions[0].invite_token) {
            let inv = this.invites.find(inv => inv.invite_token === batch.user_permissions[0].invite_token);
            if (inv) {
              inv.permissions = row.permissionsObj;
            }
          }
          this.$toast.success({ message: "User permissions have been updated!" });
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error({message: err.data.message});
        });
    },

    updatePermissions () {
      // prevent Double clicks on the modal action button
      if (this.permUpdatePending) {
        return;
      }
      this.permUpdatePending = true;
      let permissions = {};
      this.permissionKeys.forEach(key => permissions[key] = this.modalPermissions.find(pair => pair.name == key).val);
      let batch = {
        subscription_id: this.$store.getters.getSubscriptionId,
        user_permissions: this.selectedUsers.map(user => {
          if (!user.inviteToken) {
            return { user_id: parseInt(user.userId), permissions: permissions };
          } else { // invite token is used as the id in tableData.
            return { invite_token: user.inviteToken, permissions: permissions };
          }
        })
      };
      this.loading = true;
      SubscriptionService.UpdatePermissions(batch)
        .then((resp) => {

          batch.user_permissions.forEach(x => {
            if (x.user_id) {
              let user = this.users.find(x => x.user_id == x.user_id);
              if (user) {
                user.permissions = permissions;
              }
            } else {
              let inv = this.invites.find(inv => inv.invite_token === x.invite_token);
              if (inv) {
                inv.permissions = permissions;
              }
            }
          });
          this.togglePermissionModal();
          this.buildTable();
          this.$toast.success({ message: "User permissions have been updated!" });
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error({message: err.data.message});
        })
        .finally(() => {
          this.loading = false;
          this.permUpdatePending = false;
        });
    },

    // send a password reset email to a user
    resetUserPasswords () {
      // prevent Double clicks on the modal action button
      if (this.resetPending) {
        return;
      }
      this.resetPending = true;
      const promises = this.selectedUsers.filter(x => x.status !== 'invited')
        .map(user => { return PasswordResetService.SendPasswordReset(user.title.email); });
      this.loading = true;

      Promise.allSettled(promises)
        .then(results => {

          let failure = results.find((result) => { result.status == "rejected"; });
          if (failure) {
            this.$toast.error({ message: "Failed to send a reset email to users!" });
          }
          else this.$toast.success({ message: "Password reset sent to users!" });
          this.toggleResetModal();
          this.buildTable();
        }).catch((err) => {
          console.error(err);
          this.$toast.error({ message: "Failed to reset passwords!" });
        }).finally(() => {
          this.loading = false;
          this.resetPending = false;
        });
    },

    // remove user from a subscription
    deleteUsers () {
      // prevent Double clicks on the modal action button
      if (this.removePending) {
        return;
      }
      this.removePending = true;
      let promises = this.selectedUsers.filter(x => x.status !== 'invited')
        .map(user => { return SubscriptionService.DeleteSubscriptionUser(user.userId); });
      this.selectedUsers.filter(x => x.status === 'invited')
        .forEach(invite => promises.push(SubscriptionService.DeleteInvite(invite.userId)));

      Promise.allSettled(promises)
        .then(results => {

          let failure = results.find((result) => { result.status == "rejected"; });
          if (failure) {
            this.$toast.error({ message: "Failed to delete all users!" });
          } else this.$toast.success({ message: "all selected users were removed!" });

          // this.tableData = this.tableData.filter(row => !this.selectedUsers.find(user => user.userId === row.userId))
          this.users = this.users.filter(row => !this.selectedUsers.find(user => user.userId === row.user_id));
          this.invites = this.invites.filter(row => !this.selectedUsers.find(user => user.userId === row.invite_token));

          this.toggleDeleteModal();
          this.buildTable();
        }).catch((err) => {
          console.error(err);
          this.$toast.error({ message: "Failed to delete all users!" });
        }).finally(() =>
          this.removePending = false
        );
    },
    // the following is used to copy billing address for mutation, and setting default values when a user is trialing
    // makes use of the country list
    setBillingAddress ({ billing_address }) {
      helpers.setBillingAddress.call(this, billing_address);
    },
    // update modal event handlers
    toggleSubscriptionModal () {
      this.subscriptionModalDisplay = !this.subscriptionModalDisplay;
    },

    toggleThreeDSecureModal () {
      this.threeDSecureUpgradeModalDisplay = !this.threeDSecureUpgradeModalDisplay;
    },

    // incoming new sub from Update modals. triggered on completed form submissions
    subscriptionUpdate (sub) {
      this.subscription = sub;
      this.loading = false;
    },

    setLoading (bool) {
      this.loading = bool;
    },
    pendingRequest () {
      // this.loading = true;
    }
  },

  created () {
    this.countryList = helpers.setCountryList(this.COUNTRY_ISO_MAP, this.STATE_ISO_MAP);

    let coreDataPromise = Promise.all([SubscriptionService.GetSubscriptionUsers(), SubscriptionService.GetInvites(), SubscriptionService.GetSubscription()])
      .then(
        this.handlePageData
      ).catch((e) => {
        console.error("failed to get page data:" + e);
        this.errorMessage = "failed to get page data!";
        this.callToAction = {
          text: "there was an error, please try again",
          action: () => this.$router.go()
        };
        this.$toast.error({ message: `There was an issue with loading data, if it persists please contact support` });
      });
    // break-out product request, not needed for the core functionality of the page.
    if (this.userPerms.billing_management) {
      const productPromise = ProductService.GetProducts()
        .then(resp => {
          this.productList = resp.data.products;
        })
        .catch(err => {
          console.log(err);
        });
      Promise.allSettled([coreDataPromise, productPromise]).then(() => {
        this.loadingProduct = false;
        this.setBillingAddress(this.subscription.payment_profile);
      });
    }
  }
};