/* UpdateModals
* Used as an abstraction of subscription action modals, controls the set of actions and requests.
* This component concerns itself with the changing subscription information allowing the parent
* component to act on the changes to update rendered information.
*/
<template>
  <div>

    <StripeSubscriptionModal v-if="!loading && subscriptionModalDisplay !== undefined" class="m-auto"
                             :productList="productList"
                             :subscription="subscription"
                             :toDisplay="subscriptionModalDisplay"
                             :countryList="countryList"
                             :upgrade="upgrade"
                             @subscriptionToggleEvent="toggleSubscriptionModal"
                             @upgradeEvent="stripeUpgradeSubscription"
                             @updateBillingEvent="updateBilling"
                             @updateShippingEvent="updateShipping" />

    <BillingModal v-if="!loading && billingModalDisplay !== undefined" class="m-auto"
                  :subscription="subscription"
                  :toDisplay="billingModalDisplay"
                  :countryList="countryList"
                  @billingToggleEvent="toggleBillingModal"
                  @updateEvent="updateBilling" />

    <ShippingModal v-if="!loading && shippingModalDisplay !== undefined" class="m-auto"
                   :subscription="subscription"
                   :toDisplay="shippingModalDisplay"
                   :countryList="countryList"
                   @shippingToggleEvent="toggleShippingModal"
                   @updateEvent="updateShipping" />

    <three-d-secure-modal v-if="!loading && threeDSecureUpgradeModalDisplay !== undefined"
                          :link="threeDSecureRedirect"
                          :toDisplay="threeDSecureUpgradeModalDisplay"
                          @threeDSecureToggleEvent="toggleThreeDSecureModal" />
  </div>
</template>
<script>
import SubscriptionService from "Services/SubscriptionService";
import helpers from "Modules/SubscriptionHelper";
import StripeSubscriptionModal from "Components/forms/StripeSubscriptionModal/StripeSubscriptionModal.vue";
import BillingModal from "Components/forms/BillingModal/BillingModal.vue";
import ShippingModal from "Components/forms/ShippingModal/ShippingModal.vue";
import ThreeDSecureModal from "Components/forms/ThreeDSecureModal/ThreeDSecureModal.vue";

export default {

  name: 'UpdateModals',
  components: {
    BillingModal,
    ShippingModal,
    StripeSubscriptionModal,
    ThreeDSecureModal,
  },
  props: ["loading", "subscription", "productList", "upgrade", "countryList",
    "subscriptionModalDisplay", "billingModalDisplay", "shippingModalDisplay", "threeDSecureUpgradeModalDisplay"],
  data () {
    return {
      billingInfo: { address: "", address2: "", city: "", zip: "", country: "", state: "", first_name: "", last_name: "" },
      shippingInfo: { address: "", address2: "", city: "", zip: "", country: "", state: "", first_name: "", last_name: "" },
      tableData: [],
      colDef: {},
      threeDSecureRedirect: "",
      errorMessage: "",
      productInactive: false,
    };
  },
  computed: {
    userPerms () {
      return this.$store.getters.getUserPerms;
    },
  },
  methods: {

    toggleSubscriptionModal () {
      this.$emit("toggleSubscriptionModal");
    },

    toggleBillingModal () {
      this.$emit("toggleBillingModal");
    },

    toggleShippingModal () {
      this.$emit("toggleShippingModal");
    },

    toggleThreeDSecureModal () {
      this.$emit("toggleThreeDSecureModal");
    },

    // subscription update functions
    updateShipping (updateEvent,suppressToast) {
      const subscription = {
        "subscription_id": this.subscription.id,
        "shipping_address": updateEvent.shippingInfo,
      };
      // emit update Shipping
      // this.$emit("pendingRequest", "shipping");
      SubscriptionService.PostSubscription(subscription)
        .then((resp) => {
          this.$emit("subscriptionUpdate", resp.data);// emit subscription
          if (!suppressToast){
            this.$toast.success({ message: "Your shipping details have been updated" });
          }
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error({ message: "There was an error with updating your subscription! Please contact support so we can lend a hand." });
        })
        .finally(() => {
          if (this.shippingModalDisplay)
            this.toggleShippingModal();
        });
    },

    updateBilling (updateData) {
      // emit update billing
      this.$emit("pendingRequest", "billing");
      if (updateData.chargifyToken) {
        throw "User on a Chargify sub attempted to update their billing info";
      } else {
        SubscriptionService.UpdateStripeSubscription(updateData.stripeToken)
          .then((resp) => {
            this.$emit("subscriptionUpdate", resp.data);// emit subscription
            this.$toast.success({ message: "Your billing information has been updated!" });
          })
          .catch((err) => {
            if (err.data.message === "Payment requires 3D Secure Authentication") {
              this.handleStripe3ds(err);
              return;
            }
            console.error(err);
            this.$toast.error({ message: "There was an error with updating your billing information! Please contact support so we can lend a hand." });
          })
          .finally(() => {
            if (this.billingModalDisplay)
              this.toggleBillingModal();
          });
      }

    },

    stripeUpgradeSubscription ({ dictationSeats, verifiedSeats, products, stripeToken, shippingData }) {
      if (this.$store.getters.getBillingProvider == "chargify") {
        return this.reactivateChargifyOnStripe({ dictationSeats, verifiedSeats, products, stripeToken, shippingData });
      }
      this.$emit("pendingRequest", "subscription");
      return SubscriptionService.UpgradeSubscriptionProduct(products, stripeToken)
        .then((resp) => {
          this.$toast.success({ message: "Congratulations on your new subscription! You'll find all your updated subscription information below" });
          this.$emit("subscriptionUpdate", resp.data);
          return resp;
        })
        .catch((err) => {
          if (err.data.message === "Payment requires 3D Secure Authentication") {
            this.handleStripe3ds(err);
            return;
          }
          return Promise.reject(err);
        });
    },
    reactivateChargifyOnStripe ({ dictationSeats, verifiedSeats, products, stripeToken, shippingData }) {
      this.$emit("pendingRequest", "reactivate");
      let sub = undefined;
      return SubscriptionService.ReactivateChargifyOnStripe(products, stripeToken.id)
        .then((resp) => {
          this.$toast.success({ message: "Congratulations on your new subscription! You'll find all your updated subscription information below" });
          sub =  resp.data; //this is done in the updateShipping
          return this.$store.dispatch("refresh");
        })
        .then(() => {
          this.$emit("subscriptionUpdate", sub); //this is done in the updateShipping
          return this.updateShipping(shippingData, true);
        })
        .catch((err) => {
          if (err.data.message === "Payment requires 3D Secure Authentication") {
            this.handleStripe3ds(err);
            return;
          }
          this.$emit('setLoading',false);
          console.log(err);
          this.$toast.error({ message: "There was an error with updating your billing information! Please contact support so we can lend a hand." });
          this.$emit("pendingRequest", "reactivate");
        });
    },


    handleStripe3ds (threeDSecureErr) {
      this.$emit("setLoading", false);
      this.threeDSecureRedirect = helpers.handle3dsStripeError.call(this, threeDSecureErr);
      this.$emit("toggleThreeDSecureModal");
    },
    handle3ds (threeDSecureErr) {
      this.$emit("setLoading", false);

      this.threeDSecureRedirect = helpers.handle3dsError.call(this, threeDSecureErr);
      console.log(this.threeDSecureRedirect);
      this.$emit("toggleThreeDSecureModal");
    },
  },
};


</script>
<style scoped></style>


