<template>
  <template v-if="isUserScribe && !showScribeNewApp && noteId == undefined">
    <component :is="comp" :noteUsage="noteUsage" :kanbanView="isKanbanView" @noteViewChange="noteViewChange"></component>

  </template>
  <template v-else>
    <template v-if="noteId !== undefined">
      <!-- render note view-->
      <component :is="noteViewEntry"></component>
    </template>
    <template v-else-if="windowWidth < 1024">

      <div class="bg-[#E7EBF2] rounded-md flex flex-col flex-grow">
        <component :is="NotesBlade"></component>
      </div>
    </template>
    <!-- <template v-else-if="isUserScribe && !showScribeNewApp">
      <component :is="comp" :noteUsage="noteUsage" :kanbanView="isKanbanView" @noteViewChange="noteViewChange"></component>
    </template> -->
    <StandardTemplate v-else>
      <!-- note empty state -->
      <div class="relative flex flex-grow flex-col justify-center p-5 min-h-full">

        <div class="flex p-5 2xl:pr-24 mb-10 mx-auto rounded-xl bg-[#F5F6F8]">
          <div class="mr-6">
            <img v-if="userProfile?.profilePhoto"
                 :src="`https://storage.googleapis.com/assets.talkatoo.ai/profile-photos/${userProfile.profilePhoto}`"
                 class="block h-16 w-16 min-w-[4rem] p-1 rounded-full
                aria-selected:border-3 aria-selected:border-main-alt aria-selected:rounded-sm" />
          </div>
          <template v-if="noteUsage.notesUsedInPeriod !== undefined">
            <div class="flex flex-col my-auto">
              <span class="text-neutral-darker text-lg font-extrabold">
                <template v-if="noteUsage.notesUsedInPeriod == 0"> Start recording Notes with Talkatoo!</template>
                <template v-else-if="noteUsage.notesUsedInPeriod > 0"> Keep on saving time!</template>
                <template v-else-if="noteUsage.notesUsedInPeriod > 5"> You're on a roll!</template>
              </span>
              <span class="font-roboto text-xs text-center font-bold text-neutral-darker items-center">
                <template v-if="noteUsage.notesUsedInPeriod == 0"> It takes a few notes to get really effective, <br /> try a few notes to get the hang of our AI templating.</template>
                <template v-else> {{ noteUsage.notesUsedInPeriod }} Notes recorded <span class="inline-block">from {{ noteUsage.start }} - {{ noteUsage.end }}</span></template>
              </span>
            </div>
          </template>
          <div v-else class="h-16 w-80"></div>
        </div>

        <BalloonAnimals v-if="windowHeight > 870" class="mx-auto mb-3" />
        <!-- referral -->
        <div class="pt-2 hidden lg:block mx-auto text-center bg-white ">
          <div class="block mx-auto font-extrabold text-neutral-alt text-xl mb-4">
            Loving Talkatoo? Share that love <br /> with a friend to earn discounts.
          </div>
          <div class="text-sm font-roboto text-neutral mb-8">
            Refer a friend to Talkatoo and earn up to $600!<br />Copy the link below and share it with anyone who may benefit from Talkatoo.
          </div>
          <div class="flex relative justify-center">
            <button class="block lg:w-60 mx-auto mb-3 py-3 px-4 rounded-full  w-fill font-bold  text-white bg-main-alt hover:bg-main transition-colors"
                    @click="copyReferralLink">
              Copy Referral Link
            </button>
            <Transition name="fade-pull">
              <div v-if="showCopied" class="absolute  -top-8 default-fade-duration bg-white rounded-md text-rare2 font-bold px-2 py-1">
                Copied!
              </div>
            </Transition>
          </div>
          <div id="referral-link" class="mx-auto text-[10px] text-neutral-light font-roboto">
            {{ referralLink }}
          </div>
        </div>
      </div>
    </StandardTemplate>
  </template>

</template>

<script>
import Notes from "./Notes.vue";
import Kanban from "@/views/NotesKanban/Kanban.vue";
import NotesBlade from "@/components/layout/TheBlade/NotesBlade.vue";
import NoteViewEntry from "@/views/NoteView/NoteViewEntry.vue";
import { QueryManager, NoteViews, Operators, SupportedTypes } from "Modules/NotesQueryManager.js";
import SubscriptionService from "Services/SubscriptionService";
import StandardTemplate from "@/components/layout/StandardTemplate.vue";
import MixpanelService from "Services/MixpanelService";
import { shallowRef } from "vue";
import BalloonAnimals from "@/components/ui/BalloonAnimals.vue";

export default {
  name: "NotesEntry",
  components: {
    StandardTemplate, BalloonAnimals, NotesBlade,
  },
  props: {},
  data () {
    let view = this.$store.getters.getNotesView;

    let tmp = Notes;
    let viewBool = view === 'kanban';
    if (viewBool) {
      tmp = Kanban;
    }
    let comp = shallowRef(tmp);

    return {
      view,
      noteUsage: {},
      isKanbanView: viewBool,
      comp,
      noteViewEntry: shallowRef(NoteViewEntry),
      notesUsage: {},
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      NotesBlade: shallowRef(NotesBlade),
      showCopied:false,
    };
  },
  mounted () {

    if (this.isUserScribe || this.isUserVerified) {
      SubscriptionService.GetSubscription()
        .then(resp => {
          if (!resp.data.payment_profile) return;
          let nextBillingDate = new Date(resp.data.payment_profile.next_billing_at);
          let lastBillingDate = new Date(resp.data.payment_profile.next_billing_at);
          lastBillingDate.setMonth(lastBillingDate.getMonth() - 1);
          this.noteUsage.start = Intl.DateTimeFormat(undefined, { month: "short", day: "numeric" }).format(lastBillingDate.getTime());
          this.noteUsage.end = Intl.DateTimeFormat(undefined, { month: "short", day: "numeric" }).format(nextBillingDate.getTime());
          new QueryManager({ paged: true, pageSize: 1 }).getNotesWith({
            filters: [{ field: "date_filter", value: [parseInt(lastBillingDate.getTime() / 1000), parseInt(nextBillingDate.getTime() / 1000)], op: Operators.BT, type: SupportedTypes.INT }],
            // sorting: ,
            page: 1,
            noteView: NoteViews.ALL,
          }).then(x => {
            this.noteUsage.notesUsedInPeriod = x.data.total_count;
          });
        });
    } else {
      let now = new Date(new Date().setHours(23, 59, 59, 999));
      // let monthAgo = new Date(new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 30)).setHours(0, 0, 0, 0));
      let monthAgo = new Date();
      monthAgo = new Date(new Date(monthAgo.setMonth(monthAgo.getMonth() - 1)).setHours(0, 0, 0, 0));

      new QueryManager({ paged: true, pageSize: 1 }).getNotesWith({
        filters: [{ field: "date_filter", value: [parseInt(monthAgo.getTime() / 1000), parseInt(now.getTime() / 1000)], op: Operators.BT, type: SupportedTypes.INT }],
        // sorting: ,
        page: 1,
        noteView: NoteViews.ALL,
      }).then(x => {
        this.noteUsage.notesUsedInPeriod = x.data.total_count;
        this.noteUsage.start = Intl.DateTimeFormat(undefined, { month: "short", day: "numeric" }).format(monthAgo.getTime());
        this.noteUsage.end = Intl.DateTimeFormat(undefined, { month: "short", day: "numeric" }).format(now.getTime());

      }).catch((e) => {
        console.warn('error on getting notes usage:', e);
      });
    }
  },
  created () {
    this.$nextTick(() => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    noteId () {
      return this.$route.params.noteId;
    },
    userProfile () {
      return this.$store.getters.getUserProfile;
    },
    isUserScribe () {
      if (!this.$store.getters.getUserGroups) return false;
      return this.$store.getters.getUserGroups.some(x => x === "Scribe");
    },
    isUserVerified () {
      return this.$store.getters.getUserPerms.verified;
    },
    // special feature flag to enable showing the scribe the new app
    showScribeNewApp () {
      return this.$store.getters.getFeatureFlags.showScribeNewApp;
    },
    referralLink () {
      if (!this.userProfile?.email) return 'https://talkatoo.com/lunch-and-learn-referral/';
      let query = this.userProfile.email ? `?email=${encodeURIComponent(this.userProfile.email)}` : '';
      return `https://talkatoo.com/lunch-and-learn-referral/${query}`;
    }
  },

  methods: {
    copyReferralLink () {
      this.showCopied = false;
      try {
        navigator.clipboard.writeText(this.referralLink);
      } catch (err) {
        console.log(err);
        let link = document.getElementById('referral-link');
        let range = document.createRange();
        range.selectNode(link);
        window.getSelection().removeAllRanges(); // clear current selection
        window.getSelection().addRange(range); // to select text
        document.execCommand("copy", false, link.innerText);
        window.getSelection().removeAllRanges();
      }
      setTimeout(()=>{
      this.showCopied = true;
      },0);
      setTimeout(()=>{
        this.showCopied = false;
      },3000);
    },
    noteViewChange (view) {
      localStorage.setItem("notesView", view);
      this.$store.commit("set_notes_view", view);
      switch (view) {
        case ('kanban'): this.isKanbanView = true;
          this.comp = shallowRef(Kanban); break;
        case ('table'):
        default: this.isKanbanView = false;
          this.comp = shallowRef(Notes);
      }
      this.logViewChange(view);
    },
    logViewChange (view) {
      let subId = this.$store.getters.getSubscriptionId;
      MixpanelService.Track("AdminPortal:NotesViewSelection", {
        subscription_id: subId,
        note_id: this.noteId,
        view: view,
      });
    },
    onResize () {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
  },

};
</script>
<style scoped>
.pull-duration {
  transition-duration: 0.3s, 0.3s;
  /* transition-duration: 30s, 30s; */
}
</style>