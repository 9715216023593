<template>
  <BaseModal class="modal-container"
             title="Update Billing Information"
             :toDisplay="toDisplay"
             @toggleEvent="toggleModal">

    <div v-if="loading" class="absolute w-full h-full z-50 bg-black bg-opacity-10">
      <BaseLoading class="z-50 m-auto" />
    </div>
    <div class="block pb-6 pt-8 max-w-xl lg:max-w-2xl">
      <StripeForm
        :loading="loading"
        :billingAddress="subscription.payment_profile.billing_address"
        :countryList="countryList"
        :subscription="subscription"
        @toggleLoading="loading = !loading"
        @cancelEvent="toggleModal"
        @validTokenEvent="updateStripeToken"
      />
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "Components/ui/BaseModal.vue";
import StripeForm from "./StripeForm/StripeForm.vue";
import BaseLoading from "Components/ui/BaseLoading.vue";

export default {
  name: "BillingModal",
  components: { BaseModal, StripeForm, BaseLoading },
  props: ["toDisplay", "subscription", "countryList"],

  data () {
    return {
      stripeToken: undefined,
      title: "Update Your Payment Information",
      loading: false,
    };
  },
  watch: {
    toDisplay (newVal) {
      // makes sure when the modal is shown we will not have the loading animation on modal re-open
      this.loading = !newVal;
    },
  },
  methods: {
    toggleModal: function () {
      this.$emit("billingToggleEvent", this.toDisplay);
    },
    updateStripeToken (event) {
      this.loading = true;
      this.stripeToken = event.token;
      this.$emit("updateEvent", { stripeToken: this.stripeToken });
    },
  },
};
</script>
<style scoped>
.bottom-border {
  border-bottom: solid 1px;
  @apply border-gray-400;
}

.modal-container {
  width: calc(100vw - 2rem);
  position: absolute;
  @apply mx-2;
}

@media (max-width: 640px) {
  .modal-container {
    width: auto;
  }
}
</style>