import axios from 'axios';
import { store } from '../store';

const SERVICE_URL = __API_URL_V2 + 'products';

const GetProducts = () => {
  return axios({
    method: 'GET',
    url: SERVICE_URL,
  });
};


export {
  GetProducts,
};